import { RouteConfig } from 'vue-router';
import { clientConfig } from '@/helpers/clientData';

const whitelabelConfig = clientConfig();

const { modules: { landing } } = whitelabelConfig;

export default <RouteConfig[]> [
  {
    path: '/',
    redirect: landing ? '/landing' : '/login',
  },
  {
    path: '/landing',
    component: (): Promise<object> => import(/* webpackChunkName: "landing" */ '@/modules/landing/components/Landing.vue'),
  },
];
