import { Module } from 'vuex';
import { State } from '@/store/models';
import authModal from './authModal';
import idin from './idin';
import payment from './payment';
import investments from './investments';
import payments from './payments';
import assets from './assets';
import valuations from './valuations';
import identificationChecks from './identificationChecks';
import downloads from './downloads/downloads';
import checkout from './checkout';
import projects from './project';
import assetEarnings from './assetEarnings';
import assetCosts from './assetCosts';

export default <{ [key: string]: Module<any, State> }>{
  idin,
  payment,
  investments,
  payments,
  assets,
  projects,
  valuations,
  identificationChecks,
  downloads,
  authModal,
  checkout,
  assetEarnings,
  assetCosts,
};
