import Vue from 'vue';
import to from 'await-to-js';
import { Module, ActionContext } from 'vuex';
import firebase from 'firebase/app';
import { StateSlice, InitialStateSlice, State } from '@/store/models';
import { functions, db } from '@/firebase';
import store from '@/store';
import { CheckoutCloudFunctionParameters, GenericPaymentMethods } from '@/store/models/checkout';
import { clientConfig } from '@/helpers/clientData';
import { PaymentProvider, PaymentResult } from '@/store/models/investment';
import { BindingOptions } from 'vuex-class/lib/bindings';
/* import { bindRef, unbindRef } from '@/store/actions'; */

const PAYMENT_INIT_ERROR = 'PAYMENT_INIT_ERROR';
const PAYMENT_INIT_SUCCESS = 'PAYMENT_INIT_SUCCESS';
const PAYMENT_INIT_PROCESSING = 'PAYMENT_INIT_PROCESSING';
const GET_PAYMENT_ERROR = 'GET_PAYMENT_ERROR';
const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
const GET_PAYMENT_PROCESSING = 'GET_PAYMENT_PROCESSING';
const RESET_PAYMENT = 'RESET_PAYMENT';

export interface PaymentInitData {
  redirectUrl: string;
  lang: string;
  brand: string;
  type?: string;
  getLoansTotalInvested?: number;
}

export default <Module<StateSlice, State>>{
  state: new InitialStateSlice(),
  mutations: {
    [PAYMENT_INIT_ERROR](state: StateSlice, error: any): void {
      Vue.set(state, 'status', 'error');
      Vue.set(state, 'error', error.message || 'Something went wrong');
      Vue.set(state, 'name', PAYMENT_INIT_ERROR);
    },
    [PAYMENT_INIT_SUCCESS](state: StateSlice, payload: any): void {
      Vue.set(state, 'status', 'success');
      Vue.set(state, 'payload', payload);
      Vue.set(state, 'name', PAYMENT_INIT_SUCCESS);
    },
    [PAYMENT_INIT_PROCESSING](state: StateSlice): void {
      Vue.set(state, 'status', 'processing');
      Vue.set(state, 'name', PAYMENT_INIT_PROCESSING);
    },
    [GET_PAYMENT_ERROR](state: StateSlice, error: any): void {
      Vue.set(state, 'status', 'error');
      Vue.set(state, 'error', error.message || 'Something went wrong');
    },
    [GET_PAYMENT_SUCCESS](state: StateSlice, payload: any): void {
      Vue.set(state, 'status', 'success');
      Vue.set(state, 'payload', payload);
    },
    [GET_PAYMENT_PROCESSING](state: StateSlice): void {
      Vue.set(state, 'status', 'processing');
      Vue.set(state, 'payload', null);
    },
    [RESET_PAYMENT](state: StateSlice): void {
      Vue.set(state, 'status', '');
      Vue.set(state, 'payload', null);
      Vue.set(state, 'error', '');
      Vue.set(state, 'name', '');
    },
  },
  actions: {
    async paymentInit(
      { commit, getters, rootState }: ActionContext<StateSlice, State>,
      data: PaymentInitData,
    ): Promise<{ data: PaymentResult }> {
      commit(PAYMENT_INIT_PROCESSING);

      const whitelabelConfig = clientConfig();
      const isPsp = whitelabelConfig.paymentServiceProvider !== PaymentProvider.Custom;
      const paymentMethod = getters.getCheckout.paymentMethod as GenericPaymentMethods;
      const fundTypeEnabled = whitelabelConfig.functionality.paymentInitTransaction.paumentInit.fundTypeEnabled;
      const signatureChoiceEnabled = whitelabelConfig.functionality.paymentInitTransaction.paumentInit.signatureChoiceEnabled;
      const assetValuationIdEnabled = whitelabelConfig.functionality.paymentInitTransaction.paumentInit.assetValuationIdEnabled;
      const legalDocsEnabled = whitelabelConfig.functionality.paymentInitTransaction.paumentInit.legalDocsEnabled;
      const legalDocEnabled = whitelabelConfig.functionality.checkout.checkoutLegal.enableLegalDoc;
      const knowledgeQuestionAndAnswersEnabled = whitelabelConfig.functionality.paymentInitTransaction.paumentInit.knowledgeQuestionAndAnswersEnabled;
      const lighterLegalDocs = getters.getCheckout.legalDocuments && getters.getCheckout.legalDocuments.map((doc): any => ({ name: doc.name, file: doc.fileBase64 }));

      const dataToSend: CheckoutCloudFunctionParameters = {
        ...data,
        assetId: getters.getCheckout.assetId,
        selectedDividendsFormatYear: getters.getCheckout.selectedDividendsFormatYear,
        sharesAmount: getters.getCheckout.sharesAmount,
        ...(!fundTypeEnabled && getters.getAssetById(getters.getCheckout.assetId).premium && {
          type: 'loan',
        }),
        ...(fundTypeEnabled && {
          type: getters.getAssetById(getters.getCheckout.assetId).fundType,
        }),
        ...(signatureChoiceEnabled && {
          signatureChoice: getters.getCheckout.signatureChoice,
        }),
        ...(assetValuationIdEnabled && {
          assetValuationId: getters.getCheckout.assetValuationId,
        }),
        ...((legalDocsEnabled || legalDocEnabled) && {
          legalDocs: lighterLegalDocs,
        }),
        ...(knowledgeQuestionAndAnswersEnabled && {
          knowledgeQuestionAndAnswers: getters.getCheckout.knowledgeQuestionAndAnswers,
        }),
        ...(isPsp && paymentMethod) && {
          paymentMethod,
        },
      };

      // Unbind firestore documents so it's update inside paymentInitTransaction doesn't create a conflict inside VuexFire
      store.dispatch('unbindRef', { name: 'payments', reset: false });

      const [paymentInitError, paymentInitSuccess] = await to<firebase.functions.HttpsCallableResult, firebase.functions.HttpsError>(
        functions.httpsCallable('paymentInitTransaction')(dataToSend),
      );

      // Bind documents again after function execution
      const investorRef = db.collection('investors').doc(rootState!.user!.id);
      store.dispatch('bindRef', {
        name: 'payments',
        ref: db.collectionGroup('payments')
          .where('investor', '==', investorRef)
          .where('deleted', '==', false)
          .orderBy('updatedDateTime', 'desc'),
      });

      if (paymentInitError) {
        commit(PAYMENT_INIT_ERROR, paymentInitError);
        throw paymentInitError;
      }
      commit(PAYMENT_INIT_SUCCESS, paymentInitSuccess);
      return paymentInitSuccess as { data: PaymentResult};
    },
    async getPayment({ commit }: ActionContext<StateSlice, State>, { id }: any): Promise<void> {
      commit(GET_PAYMENT_PROCESSING);
      const [getPaymentError, getPaymentSuccess] = await to(db.collection('payments').doc(id).get());
      if (getPaymentError) {
        commit(GET_PAYMENT_ERROR, getPaymentError);
      } else {
        commit(
          GET_PAYMENT_SUCCESS,
          (getPaymentSuccess as firebase.firestore.DocumentSnapshot).data(),
        );
      }
    },
    resetPayment({ commit }: ActionContext<StateSlice, State>): void {
      commit(RESET_PAYMENT);
    },
  },
};
