import { firebase } from '@/firebase';
import { Module } from 'vuex';
import BigNumber from 'bignumber.js';
import { State } from '@/store/models';
import { Payment, PaymentStatus } from '@/store/models/investment';
import { formatNumber } from '@/filters/number';
import { clientConfig } from '@/helpers/clientData';
import { Asset } from '../models/asset';

const whitelabelConfig = clientConfig();
const scheduleEnding = whitelabelConfig.functionality.scheduleEnding;
export interface InvestmentsArray<T> extends Array<T> {
  totalLength?: number;
}

export interface CombinedDividendsFormat {
  euroAmountDividends: number,
  dividendsFormat: [string, number]
}

/**
 * Function that shows two decimals if there are any
 * @param numb
 */
const showTwoDecimalsOrNone = (numb): number => numb % 1 !== 0 ? Number(formatNumber(numb, 2)) : numb;

// get the paymentDate if paymentDate itself is undefined
export const getPaymentDate = (payment: Payment): firebase.firestore.Timestamp => payment.paymentDateTime ?? payment.updatedDateTime ?? payment.createdDateTime;

export default <Module<Payment[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getPaymentById: (state): Function =>
      (paymentId: string): Payment | undefined => state.find((payment): boolean => payment.id === paymentId),
    getPaymentByAsset: (state): Function =>
      (assetId: string): Payment | undefined =>
        state.find((payment): boolean => payment.asset.id === assetId),
    getPaymentsByAsset: (state): Function =>
      (assetId: string): Payment[] | undefined =>
        state.filter((payment): boolean => payment.asset.id === assetId),
    getPaymentsByInvestmentId: (state): Function =>
      (investmentId: string): Payment[] => state.filter((payment): boolean => payment.investment.id === investmentId && !payment.deleted),
    getPaidPaymentsByInvestmentId: (state): Function =>
      (investmentId: string): Payment[] => state.filter(
        (payment): boolean => payment.investment.id === investmentId && payment.providerData.status === PaymentStatus.Paid && !payment.deleted,
      ).sort((a, b): number => getPaymentDate(b).toMillis() - getPaymentDate(a).toMillis()),
    getPaidOrOpenPaymentsByInvestmentId: (state): Function =>
      (investmentId: string): Payment[] => state.filter(
        (payment): boolean => payment.investment.id === investmentId && (payment.providerData.status === PaymentStatus.Paid || payment.providerData.status === PaymentStatus.Open) && !payment.deleted,
      ).sort((a, b): number => getPaymentDate(b).toMillis() - getPaymentDate(a).toMillis()),
    getPaidPayments: (state): Payment[] => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Paid,
    ),
    getPaidOrOpenPayments: (state): Payment[] => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Paid || payment.providerData.status === PaymentStatus.Open,
    ),
    getOpenPayments: (state): Payment[] => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Open,
    ),
    getOpenOrRequestedPayments: (state): Payment[] => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Open || payment.providerData.status === PaymentStatus.Requested,
    ),
    getPaidOpenRequestedPayments: (state): Payment[] => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Open || payment.providerData.status === PaymentStatus.Paid || payment.providerData.status === PaymentStatus.Requested,
    ),
    // Get the number of investments that have at least one paid payment
    getLengthPaidPayments: (state): number => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Paid,
    ).length,
    getDividendsByAssetYearly: (state): Function =>
      (assetId: string): [string, number][] => state.reduce((divByAsset, payment): [string, number][] => {
        const tempDivByAsset = [...divByAsset];
        if (payment.asset.id === assetId) {
          tempDivByAsset.push([
            payment.dividendsFormat[0],
            showTwoDecimalsOrNone(payment.providerData.metadata.euroAmount * (payment.dividendsFormat[1] / 100)),
          ]);
        }
        return divByAsset;
      }, [] as [string, number][]),
    getTotalDividendsPerYear: (state, getters): number => {
      const result = getters.getPaidPayments.reduce((
        paymentA,
        paymentB,
      ): number => paymentA + (
        // Only for paid payments
        (((scheduleEnding && (paymentB.scheduleEnding && paymentB.ended)) && !paymentB.deleted) || !paymentB.ended)
          ? paymentB.providerData.metadata.euroAmount * (paymentB.dividendsFormat[1] / 100)
          : 0
      ), 0);

      // Only 2 decimals
      return showTwoDecimalsOrNone(result);
    },
    getTotalDividendsPerMonth: (state, getters): number => {
      const result = new BigNumber(getters.getTotalDividendsPerYear).dividedBy(12).toNumber();

      return showTwoDecimalsOrNone(result);
    },
    getTotalDividendsPerYearByAssetType: (state, getters): Function =>
      (assetType: string): number => {
        const result = (getters.getPaidPayments as Payment[])
          .filter((payment): boolean => (payment.asset as Asset).fundType === assetType)
          .reduce((
            paymentA,
            paymentB,
          ): number => paymentA + (
            // Only for paid payments
            (((scheduleEnding && (paymentB.scheduleEnding && paymentB.ended)) && !paymentB.deleted) || !paymentB.ended)
              ? paymentB.providerData.metadata.euroAmount * (paymentB.dividendsFormat[1] / 100)
              : 0
          ), 0);
        // Only 2 decimals
        return showTwoDecimalsOrNone(result);
    },
    getTotalDividendsPerMonthByAssetType: (state, getters): Function =>
      (assetType: string): number => {
        const result = new BigNumber(getters.getTotalDividendsPerYearByAssetType(assetType))
          .dividedBy(12)
          .toNumber();
          // Only 2 decimals
        return showTwoDecimalsOrNone(result);
    },
    getTotalEquityDividendsPerYear: (state, getters): number => {
      const equityPayments = getters.getPaidPayments.filter((payment): boolean => (payment.asset as Asset).fundType === 'equity');
      const result = equityPayments.reduce((paymentA, paymentB): number => paymentA + (
        !paymentB.ended ? paymentB.providerData.metadata.euroAmount * (paymentB.dividendsFormat[1] / 100) : 0
      ), 0);

      // Only 2 decimals
      return showTwoDecimalsOrNone(result);
    },
    getTotalEquityDividendsPerMonth: (state, getters): number => {
      const result = new BigNumber(getters.getTotalEquityDividendsPerYear).dividedBy(12).toNumber();

      return showTwoDecimalsOrNone(result);
    },
    getTotalBondDividendsPerYear: (state, getters): number => {
      const bondPayments = getters.getPaidPayments.filter((payment): boolean => (payment.asset as Asset).fundType === 'bond');
      const result = bondPayments.reduce((paymentA, paymentB): number => paymentA + (
        !paymentB.ended ? paymentB.providerData.metadata.euroAmount * (paymentB.dividendsFormat[1] / 100) : 0
      ), 0);

      // Only 2 decimals
      return showTwoDecimalsOrNone(result);
    },
    getTotalBondDividendsPerMonth: (state, getters): number => {
      const result = new BigNumber(getters.getTotalBondDividendsPerYear).dividedBy(12).toNumber();

      return showTwoDecimalsOrNone(result);
    },
    getTotalLoanDividendsPerYear: (state, getters): number => {
      const loanPayments = getters.getPaidPayments.filter((payment): boolean => (payment.asset as Asset).fundType === 'loan');
      const result = loanPayments.reduce((paymentA, paymentB): number => paymentA + (
        !paymentB.ended ? paymentB.providerData.metadata.euroAmount * (paymentB.dividendsFormat[1] / 100) : 0
      ), 0);

      // Only 2 decimals
      return showTwoDecimalsOrNone(result);
    },
    getTotalLoanDividendsPerMonth: (state, getters): number => {
      const result = new BigNumber(getters.getTotalLoanDividendsPerYear).dividedBy(12).toNumber();

      return showTwoDecimalsOrNone(result);
    },
    hasDifferentPaymentFormatsByAsset: (state): Function => (assetId: string): boolean => {
      const dividendFormats: Payment['dividendsFormat'][] = [];
      let whileIndex = 0;
      while (dividendFormats.length < 2 && whileIndex < state.length) {
        const payment = state[whileIndex];
        if (payment.asset.id === assetId) {
          if (!dividendFormats.some((format): boolean => payment.dividendsFormat[0] === format[0] && payment.dividendsFormat[1] === format[1])) {
            dividendFormats.push(payment.dividendsFormat);
          }
        }
        whileIndex++;
      }
      return dividendFormats.length > 1;
    },
    // keeping in case needed for the future which is a fair probability
    getCombinedDividendsFormatByAsset: (state, getters): Function => (assetId: string, allPayments?: boolean): CombinedDividendsFormat => {
      const payments = !allPayments ? getters.getPaidPayments : getters.getPaidOpenRequestedPayments;
      const group: CombinedDividendsFormat = payments.reduce((a, b): CombinedDividendsFormat => {
        if (b.asset.id !== assetId && (!b.ended || (scheduleEnding && b.ended && b.scheduleEnding))) {
          return a;
        }
        // Creating the object with the next payment
        const final: CombinedDividendsFormat = {
          ...a,
          [b.dividendsFormat[0]]: {
            euroAmount: b.providerData.metadata.euroAmount,
            sharesAmount: b.providerData.metadata.sharesAmount,
            percentage: b.dividendsFormat[1],
            dividends: new BigNumber(b.providerData.metadata.euroAmount).multipliedBy(b.dividendsFormat[1]).dividedBy(100).toNumber(),
          },
        };
        // Doing the actual reduction
        if (a[b.dividendsFormat[0]]) {
          final[b.dividendsFormat[0]].euroAmount += a[b.dividendsFormat[0]].euroAmount;
          final[b.dividendsFormat[0]].sharesAmount += a[b.dividendsFormat[0]].sharesAmount;
          final[b.dividendsFormat[0]].dividends += a[b.dividendsFormat[0]].dividends;
        }
        return final;
      }, {});

      return group;
    },
    getCombinedDividendsFormatByAssetPaidAndOpen: (state, getters): Function => (assetId: string, investmentId): CombinedDividendsFormat => {
      const group: CombinedDividendsFormat = getters.getPaidOrOpenPaymentsByInvestmentId(investmentId).reduce((a, b): any => {
        if (b.asset.id !== assetId && !b.ended) {
          return a;
        }

        return [...a, [...b.dividendsFormat]];
      }, []);

      return group;
    },
    getCombinedExpectedReturnPerDividendFormat: (state, getters): Function => (investmentId, divFormat): number | undefined => {
      const payments = getters.getPaidOrOpenPaymentsByInvestmentId(investmentId);
      if (payments.length) {
        const sumEuroAmount = payments.reduce((accumulator, payment): any => {
          if (
            payment.dividendsFormat[0] === divFormat[0] &&
            payment.dividendsFormat[1] === divFormat[1]
          ) {
            return accumulator + payment.providerData.metadata.euroAmount;
          }
          return accumulator;
        }, 0);
        return new BigNumber(sumEuroAmount).multipliedBy(divFormat[1]).dividedBy(100).toNumber();
      }
      return undefined;
    },
  },
};
