import { Module } from 'vuex';
import { firebase } from '@/firebase';
import { State } from '@/store/models';
import { AssetCosts } from '@/store/models/asset';

export default <Module<AssetCosts[], State>>{
  state: [],
  mutations: {
  },
  actions: {},
  getters: {
    getCostsByAsset: (state): Function =>
    (assetId: string): AssetCosts[] | [] => {
      const assetCosts = state.filter((assetCost): boolean => assetCost.asset.id === assetId);
      if (assetCosts) return assetCosts;
      return [];
    },
  },
};
