/* eslint-disable object-shorthand */
import to from 'await-to-js';
import pull from 'lodash.pull';
import Vue from 'vue';
import { ActionContext, ActionTree } from 'vuex';
import { firestoreAction } from 'vuexfire';
import { capitalize } from '@/filters/string';
import { auth, db, firebase, functions, storage } from '@/firebase';
import { i18n } from '@/i18n';
import router from '@/router';
import { QuestionnaireAnswer, QuestionnaireAnswers } from '@/store/models/questionnaire';
import { DataChangeRequest, Investor, KYCMethods, roles } from '@/store/models/user';
// in order to make the this context available in certain actions
import { initCheckoutStore } from '@/store/modules/checkout';
import { clientConfig } from '@/helpers/clientData';
import * as MUTATIONS from './constants';
import { State } from './models';
import { AssetReservation } from './models/asset';
import { BusinessIdentification, ItsmeIdentification, PrivateIdentification } from './models/identificationRequest';

/**
 * Options for data binding.
 */
interface BindingOptions {
  /**
   * How many levels of nested references should be automatically bound.
   * Defaults to 2, meaning that References inside of References inside
   * of documents bound with $bind will automatically be bound too.
   * @default 2
   */
  maxRefDepth?: number;

  /**
   * Allows to define the behavior when a previously bound reference is unbound.
   * - If set to true (default), resets the property in the vue instance to null
   *   for documents and to an empty array [] for collections.
   * - If set to a function, returns a custom value for the property.
   * - If set to false, keeps the data as-is when unbinding.
   * @default true
   */
  reset?: boolean | (() => any);

  /**
   * Waits for the binding to be completely resolved before setting the value in data.
   * This will also force reset: false unless reset is provided as a function.
   */
  wait?: boolean;

  /**
   * A function that receives a DocumentSnapshot as its first argument and is
   * expected to return a plain object to be set on the Vue Instance.
   * @param docSnapshot - The DocumentSnapshot received as the first argument.
   * @returns A plain object to be set on the Vue Instance.
   */
  serialize?: (docSnapshot: firebase.firestore.DocumentSnapshot) => any;
}

class Reference {
  // eslint-disable-next-line no-restricted-globals
  name: string = '';
  ref: any;
  options?: BindingOptions = {};
}

interface UnbindReference {
  name: string;
  reset?: boolean | Function;
}

const whitelabelConfig = clientConfig();
const initCheckoutStoreClient = whitelabelConfig.functionality.checkout.initCheckoutStore;
const uploadPassport = whitelabelConfig.functionality.identificationRequest.uploadPassport;

export const activeBindings: string[] = [];

const getExtension = (type: string): string => type.substring(type.lastIndexOf('/') + 1, type.length);

export type SendQuestionnaireParam = { questionnaireAnswers: QuestionnaireAnswers, userId: string };

export default <ActionTree<State, State>>{
  // Creates data binding and stores key as reference in activeBindings
  bindRef: firestoreAction(
    ({ bindFirestoreRef }, { name, ref, options }: Reference): Promise<any> => {
      if (!activeBindings.includes(name)) {
        activeBindings.push(name);
      }

      return bindFirestoreRef(name, ref, options);
    },
  ),

  // Removes data binding and removes key from activeBindings
  unbindRef: firestoreAction(({ unbindFirestoreRef }, { name, reset }: UnbindReference): void => {
    if (reset === undefined) {
      unbindFirestoreRef(name);
    } else {
      // @ts-ignore (official types bugged)
      unbindFirestoreRef(name, reset);
    }
    pull(activeBindings, name);
  }),

  // Clears all bindings based on keys in activeBindings
  clearRefs: firestoreAction(({ unbindFirestoreRef }, exceptions: string[]): void => {
    const keys: string[] = activeBindings.filter((key: string): boolean => !exceptions.includes(key));

    keys.forEach((key: string): void => {
      // @ts-ignore (official types bugged)
      unbindFirestoreRef(key, true);
      pull(activeBindings, key);
    });
  }),

  logInInit({ commit }: ActionContext<State, State>, user: any): void {
    commit(MUTATIONS.LOGIN_SUCCESS, user);
  },

  logOutInit({ commit, dispatch }: ActionContext<State, State>): void {
    commit(MUTATIONS.LOGOUT_SUCCESS);

    const exceptions: string[] = ['assets', 'operations', 'downloads', 'settings', 'admin', 'identificationSettings'];

    dispatch('clearRefs', exceptions);
    commit(MUTATIONS.RESET_STATE, exceptions);
  },

  async logIn(
    { commit, dispatch }: ActionContext<State, State>,
    { email, password }: any,
  ): Promise<void> {
    commit(MUTATIONS.LOGIN_PROCESSING);

    const [loginError, loginSuccess] = await to<firebase.auth.UserCredential, firebase.auth.Error>(
      auth.signInWithEmailAndPassword(email, password),
    );

    if (loginError) {
      commit(MUTATIONS.LOGIN_ERROR, loginError);
      return;
    }

    const user = loginSuccess!.user!;
    const userHasRole = async (): Promise<boolean> => {
      const tokenResult = await user.getIdTokenResult();
      return roles.some((role): boolean => tokenResult.claims[role]);
    };

    // Logout if email is not verified or user has a role
    if (!user.emailVerified || await userHasRole()) {
      await dispatch('logOut');
      commit(MUTATIONS.LOGIN_WARNING, loginSuccess);
    }

    if (!loginError) {
      try {
        // @ts-ignore
        this._vm.$gtm?.trackEvent({
          event: 'login',
          email,
        });
      } catch (e) { /* Silent error */ }
    }
  },

  async logOut(
    { commit }: ActionContext<State, State>,
    { redirect }: { redirect?: string, idle?: boolean } = {},
  ): Promise<void> {
    commit(MUTATIONS.LOGOUT_PROCESSING);

    const [logOutError] = await to(auth.signOut());
    if (logOutError) {
      commit(MUTATIONS.LOGOUT_ERROR, logOutError);

      return;
    }

    commit(MUTATIONS.IDLE_LOGOUT);

    if (redirect) {
      // Silenting error at navigation abortion
      router.replace(redirect).catch((): void => {
      });
    }
  },

  async signUp(
    { commit, dispatch }: ActionContext<State, State>,
    { email, password, subscribeToNewsletter }: { email: string, password: string, subscribeToNewsletter?: boolean },
  ): Promise<void> {
    commit(MUTATIONS.SIGNUP_PROCESSING);

    // Create the user form the clientside
    const [signUpError, signUpSuccess] = await to<firebase.auth.UserCredential, firebase.auth.Error>(
      auth.createUserWithEmailAndPassword(email, password),
    );
    if (signUpError || !signUpSuccess) {
      return commit(MUTATIONS.SIGNUP_ERROR, signUpError);
    }

    // Require login after registering
    dispatch('logOut');

    try {
      // @ts-ignore
      this._vm.$gtm?.trackEvent({
        event: 'signup_started',
        email,
      });
    } catch (e) { /* Silent error */ }

    // Send verification e-mail via Cloud Function
    const [sendVerifyEmailError, sendVerifyEmailSuccess] = await to(
      dispatch('sendEmailVerification', { email }),
    );

    if (sendVerifyEmailError) {
      return commit(MUTATIONS.SIGNUP_ERROR, Error('There was a problem sending the verification email, please try again or contact support.'));
    }

    if (subscribeToNewsletter) {
      const [addNewsletterSubscriberError, addNewsletterSubscriberSuccess] = await to(db.collection('newsletterSubscribers').doc(signUpSuccess.user?.uid).set({
        subscribeToNewsletter: subscribeToNewsletter,
      }));

      if (addNewsletterSubscriberError) {
        return commit(MUTATIONS.SIGNUP_ERROR, Error('There was a problem while trying to sign the user up for the newsletter.'));
      }
    }

    return commit(MUTATIONS.SIGNUP_SUCCESS);
  },

  async sendResetPassword({ commit }: ActionContext<State, State>, { email }: { email: string }): Promise<void> {
    commit(MUTATIONS.RESET_PASSWORD_PROCESSING);

    const format = whitelabelConfig.emails.passwordReset.format;

    if (format === 'salutationSurname') {
      const [getInvestorError, getInvestorSuccess] = await to(db.collection('investors').where('email', '==', email).limit(1).get());

      if (getInvestorError) {
          throw getInvestorError;
      }
      const investorDoc = getInvestorSuccess?.docs[0];
      const investorData = investorDoc?.data();
      const salutation = investorData?.gender ? investorData.gender : 'defaultSalutation';
      const surname = investorData?.surname ? investorData.surname : '';
      const middlename = investorData?.middleName ? investorData.middleName : '';

      const [sendResetEmailError, sendResetEmailSuccess] = await to(
        functions.httpsCallable('sendUserActionEmail')({ action: 'password-reset', email, salutation, surname, middlename, lang: i18n.locale }),
      );

      if (sendResetEmailError) {
        commit(MUTATIONS.RESET_PASSWORD_ERROR, sendResetEmailError);
      } else {
        commit(MUTATIONS.RESET_PASSWORD_SUCCESS, sendResetEmailSuccess);
      }
      return;
    }

    if (format === 'directorRegardsForgotReset') {
      const [sendResetEmailError, sendResetEmailSuccess] = await to(
        functions.httpsCallable('sendUserActionEmail')({ action: 'password-reset', email, lang: i18n.locale }),
      );
      if (sendResetEmailError) {
        commit(MUTATIONS.RESET_PASSWORD_ERROR, sendResetEmailError);
      } else {
        commit(MUTATIONS.RESET_PASSWORD_SUCCESS, sendResetEmailSuccess);
      }
    }
  },

  async contactSupportByMail(
    { commit, state, getters }: ActionContext<State, State>,
    data: any,
  ): Promise<void> {
    commit(MUTATIONS.CONTACT_SUPPORT_PROCESSING);

    const [sendEmailError, sendEmailSuccess] = await to(
      functions.httpsCallable('contactSupportByMail')(data),
    );

    if (sendEmailError) {
      commit(MUTATIONS.CONTACT_SUPPORT_ERROR, sendEmailError);
    } else {
      commit(MUTATIONS.CONTACT_SUPPORT_SUCCESS, sendEmailSuccess);
    }

    const eventData: { [key: string]: any } = {
      event: 'contact_form',
      email: state.user?.email,
    };

    if (getters.isInvestor()) {
      const investor = state.user as Investor;
      if (investor.kycMethod !== KYCMethods.Idin && investor.kycMethod !== KYCMethods.Itsme) {
        eventData.phone = investor.telephone;
        eventData.first_name = investor.name;
      }
      eventData.surname = investor.surname;
    }

    try {
      // @ts-ignore
      this._vm.$gtm?.trackEvent(eventData);
    } catch (e) { /* Silent error */ }
  },

  async reAuthenticate(
    { commit }: ActionContext<State, State>,
    { password }: { password: string },
  ): Promise<[firebase.auth.Error | null, firebase.auth.UserCredential | undefined]> {
    const user = auth.currentUser as firebase.User;
    const currentCredentials = firebase.auth.EmailAuthProvider.credential(user.email || '', password);

    // Before we continue, we have to (and want to) re-authenticate
    // @see https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
    return to<firebase.auth.UserCredential, firebase.auth.Error>(
      user.reauthenticateWithCredential(currentCredentials),
    );
  },

  async changePassword(
    { commit, dispatch }: ActionContext<State, State>,
    { password, oldPassword }: { password: string, oldPassword: string },
  ): Promise<void> {
    commit(MUTATIONS.CHANGE_PASSWORD_PROCESSING);

    // Before we continue, we have to (and want to) re-authenticate
    // @see https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
    const [reAuthError] = await dispatch('reAuthenticate', { password: oldPassword });
    if (reAuthError) {
      return commit(MUTATIONS.CHANGE_PASSWORD_ERROR, reAuthError.code !== 'auth/wrong-password'
        ? reAuthError.message
        : capitalize(i18n.t('common.incorrectPassword') as string));
    }

    // Make sure we are re-authenticated before we reach this code
    // @see https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
    const [changePasswordError] = await to<void, firebase.auth.Error>(
      (auth.currentUser as firebase.User).updatePassword(password),
    );
    if (changePasswordError) {
      return commit(MUTATIONS.CHANGE_PASSWORD_ERROR, changePasswordError.message);
    }
    commit(MUTATIONS.CHANGE_PASSWORD_SUCCESS);

    // Require login after registering
    return dispatch('logOut', { redirect: '/login' });
  },

  async changeNameSurname(
    { commit, state }: ActionContext<State, State>,
    { name, surname }: { name: string, surname: string },
  ): Promise<void> {
    commit(MUTATIONS.CHANGE_NAME_SURNAME_PROCESSING);

    if (state.user) {
      const [updateNameError] = await to(
        db.collection('investors').doc(state.user.id).update({ name, surname, updatedDateTime: firebase.firestore.Timestamp.now() }),
      );
      if (updateNameError) {
        return commit(MUTATIONS.CHANGE_NAME_SURNAME_ERROR, updateNameError.message);
      }

      return commit(MUTATIONS.CHANGE_NAME_SURNAME_SUCCESS);
    }

    return commit(MUTATIONS.CHANGE_NAME_SURNAME_ERROR, 'Error with user credentials.');
  },

  async changePhoneNumber(
    { commit, state }: ActionContext<State, State>,
    { phone, confirmPhoneNumber }: { phone: string, confirmPhoneNumber: string },
  ): Promise<void> {
    commit(MUTATIONS.CHANGE_PHONE_NUMBER_PROCESSING);

    if (state.user) {
      const [updateNameError] = await to(
        db.collection('investors').doc(state.user.id).update({ telephone: phone, updatedDateTime: firebase.firestore.Timestamp.now() }),
      );
      if (updateNameError) {
        return commit(MUTATIONS.CHANGE_PHONE_NUMBER_ERROR, updateNameError.message);
      }

      return commit(MUTATIONS.CHANGE_PHONE_NUMBER_SUCCESS);
    }

    return commit(MUTATIONS.CHANGE_PHONE_NUMBER_ERROR, 'Error with user credentials.');
  },

  async changeAddress(
    { commit, state }: ActionContext<State, State>,
    { country, streetAddress, houseNumber, postalCode, city, telephone }: { country: string; streetAddress: string, houseNumber: string, postalCode: string, city: string, telephone: string },
  ): Promise<void> {
    commit(MUTATIONS.CHANGE_ADDRESS_PROCESSING);
    if (state.user) {
      const [updateAddressError] = await to(
        db.collection('investors').doc(state.user.id).update({ country, streetAddress, houseNumber, postalCode, city, telephone, updatedDateTime: firebase.firestore.Timestamp.now() }),
      );
      if (updateAddressError) {
        return commit(MUTATIONS.CHANGE_ADDRESS_ERROR, updateAddressError.message);
      }

      return commit(MUTATIONS.CHANGE_ADDRESS_SUCCESS);
    }

    return commit(MUTATIONS.CHANGE_ADDRESS_ERROR, 'Error with user credentials.');
  },

  async changeEmail(
    { commit, state, dispatch }: ActionContext<State, State>,
    { email, password }: { email: string, password: string },
  ): Promise<void> {
    commit(MUTATIONS.CHANGE_EMAIL_PROCESSING);

    const { user } = state;
    if (user && auth.currentUser) {
      if (!password) {
        return commit(MUTATIONS.CHANGE_EMAIL_ERROR, 'Please, fill in current password.');
      }

      // Before we continue, we have to (and want to) re-authenticate
      // @see https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user
      const [reAuthError] = await dispatch('reAuthenticate', { password });

      if (reAuthError) {
        return commit(MUTATIONS.CHANGE_EMAIL_ERROR, reAuthError.message);
      }

      const [transactionError, transactionSuccess] = await to(
        db.runTransaction(async (transaction): Promise<void> => {
          const investorRef = db.collection('investors').doc(user.id);

          const [readBInvestorError, readInvestor] = await to(transaction.get(investorRef));
          if (readBInvestorError) {
            throw Error('There was an error reading the investor.');
          }
          if (readInvestor && !readInvestor.exists) {
            throw Error('Investor not found.');
          }

          transaction.update(investorRef, { newPendingEmail: email });

          // Update email in auth
          const [updateAuthEmailError] = await to(
            auth.currentUser!.verifyBeforeUpdateEmail(email),
          );

          if (updateAuthEmailError) {
            throw updateAuthEmailError;
          }
        }),
      );

      if (transactionError) {
        return commit(MUTATIONS.CHANGE_EMAIL_ERROR, 'Error at changing email.');
      }

      // Require login after registering
      return commit(MUTATIONS.CHANGE_EMAIL_SUCCESS);
    }

    return commit(MUTATIONS.CHANGE_EMAIL_ERROR, 'Error with user credentials.');
  },

  async sendEmailVerification({ commit }: ActionContext<State, State>, { email }: { email: string }): Promise<void> {
    commit(MUTATIONS.SEND_EMAIL_VERIFICATION_PROCESSING);

    // Use the Cloud Function to send the customised verification email
    const [sendVerifyEmailError, sendVerifyEmailSuccess] = await to(
      functions.httpsCallable('sendUserActionEmail')({ action: 'verify', email, lang: i18n.locale }),
    );

    if (sendVerifyEmailError) {
      commit(MUTATIONS.SEND_EMAIL_VERIFICATION_ERROR, sendVerifyEmailError);
    } else {
      commit(MUTATIONS.SEND_EMAIL_VERIFICATION_SUCCESS, sendVerifyEmailSuccess);
    }
  },

  async sendQuestionnaire(
    { commit, state }: ActionContext<State, State>,
    data: SendQuestionnaireParam,
  ): Promise<void> {
    commit(MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_PROCESSING);
    const storageUploads: firebase.storage.UploadTask[] = []; // collect the upload tasks in this array

    // todo don't upload files if already exists
    // store data if needed and transform to link
    const answersToStore: QuestionnaireAnswer[] = data.questionnaireAnswers.answers.map((answer, index): QuestionnaireAnswer => {
      if (answer.type === 'file') {
        const file = answer.answer as unknown as File;
        const fileName = `investors/${data.userId}/questionnaire${index}.${file.name}`;
        storageUploads.push(storage.ref().child(fileName).put(file));

        return {
          answer: fileName,
          type: 'file',
        };
      }
      return answer;
    });

    const [uploadAnswersError] = await to(Promise.all(storageUploads)); // upload files
    if (uploadAnswersError) {
      commit(MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_ERROR, uploadAnswersError.message);
      throw Error(uploadAnswersError.message);
    }

    const objToStore: QuestionnaireAnswers = {
      answers: answersToStore,
      createdDateTime: data.questionnaireAnswers.createdDateTime,
      questions: data.questionnaireAnswers.questions,
    };
    const [dbError] = await to( // write answers to firestore
      db
        .collection('investors')
        .doc(data.userId)
        .update({
          questionnaire: objToStore,
        }),
    );
    if (dbError) {
      commit(MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_ERROR, dbError.message);
      throw Error(dbError.message);
    }
    commit(MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_SUCCESS);
  },

  async uploadFileFromCheckout(
    { commit, state }: ActionContext<State, State>,
    data: any,
  ): Promise<void> {
    commit(MUTATIONS.SEND_SECONDARY_DOCUMENT_PROCESSING);

    const file = data.file;
    const operation = data.operation;

    const currentdate = new Date();

    let dynamicFileName = '';

    if (operation === 'passportSecond') {
      dynamicFileName = 'Paspoort_tweede';
    } else if (operation === 'passport') {
      dynamicFileName = 'Paspoort';
    } else if (operation === 'kvkImage') {
      dynamicFileName = 'Kvk_uittreksel';
    }

    const fileNameProcessed = `${dynamicFileName}_${currentdate
      .getFullYear()}_${currentdate.getMonth()+1}_${currentdate.getDate()}_${currentdate.getHours()}_${currentdate.getMinutes()}_${currentdate.getSeconds()}`;

    const fileName = `investors/${state.user!.id}/${fileNameProcessed}.${getExtension(file.type)}`;
    const storageRef = storage.ref().child(fileName);

    // @ts-ignore
    const [uploadError] = await to(storageRef.put(file));

    if (uploadError) {
      return commit(MUTATIONS.SEND_SECONDARY_DOCUMENT_ERROR, uploadError.message);
    }

    const [saveToDbError] = await to(
      functions.httpsCallable('updateInvestorSecondaryDocuments')({
        ...(operation === 'passportSecond' && { passportSecond: fileName }),
        ...(operation === 'passport' && { passport: fileName }),
        ...(operation === 'kvkImage' && { kvkImage: fileName }),
      }),
    );

    if (saveToDbError) {
      return commit(MUTATIONS.SEND_SECONDARY_DOCUMENT_ERROR, saveToDbError.message);
    }

    try {
      // @ts-ignore
      this._vm.$gtm.trackEvent({
        event: 'checkout_flow_upload',
        type: 'CheckoutFlowUpload',
        file: fileName,
      });
    } catch (e) { /* Silent error */ }

    return commit(MUTATIONS.SEND_SECONDARY_DOCUMENT_SUCCESS);
  },

  async deleteCheckoutSessionAction(
    { commit }: ActionContext<State, State>,
    data: any,
  ): Promise<void> {
    commit(MUTATIONS.DELETE_CHECKOUT_SESSION_PROCESSING);

    const [deleteCheckoutSessionError] = await to(
      functions.httpsCallable('deleteCheckoutSession')({ asset: data.asset, investor: data.investor }),
    );

    if (deleteCheckoutSessionError) {
      return commit(MUTATIONS.DELETE_CHECKOUT_SESSION_ERROR, deleteCheckoutSessionError.message);
    }

    try {
      // @ts-ignore
      this._vm.$gtm.trackEvent({
        event: 'session_deleted',
        type: 'CheckoutFlowSessionDelete',
      });
    } catch (e) { /* Silent error */ }

    return commit(MUTATIONS.DELETE_CHECKOUT_SESSION_SUCCESS);
  },

  async updateCheckoutSessionAction(
    { commit }: ActionContext<State, State>,
    data: any,
  ): Promise<void> {
    commit(MUTATIONS.SEND_CHECKOUT_SESSION_PROCESSING);

    const checkout = initCheckoutStore(initCheckoutStoreClient, data.checkout);

    const [updateCheckoutSessionError] = await to(
      functions.httpsCallable('updateCheckoutSession')({ checkout, asset: data.asset, investor: data.investor }),
    );

    if (updateCheckoutSessionError) {
      return commit(MUTATIONS.SEND_CHECKOUT_SESSION_ERROR, updateCheckoutSessionError.message);
    }

    try {
      // @ts-ignore
      this._vm.$gtm.trackEvent({
        event: 'session_updated',
        type: 'CheckoutFlowSessionUpdate',
      });
    } catch (e) { /* Silent error */ }

    return commit(MUTATIONS.SEND_CHECKOUT_SESSION_SUCCESS);
  },

  async sendPrivateIdentification(
    { commit, state }: ActionContext<State, State>,
    data: PrivateIdentification,
  ): Promise<void> {
    commit(MUTATIONS.SEND_PRIVATE_IDENTIFICATION_PROCESSING);

    let fileNamePassport;
    if (uploadPassport) {
      const { passport } = data;
      const passportName = whitelabelConfig.functionality.investor.passportPropertyName;

      // @ts-ignore
      fileNamePassport = `investors/${state.user!.id}/${passportName}.${getExtension(passport.type)}`;
      const storageRef = storage.ref().child(fileNamePassport);

      // @ts-ignore
      const [uploadError] = await to(storageRef.put(passport));

      if (uploadError) {
        return commit(MUTATIONS.SEND_PRIVATE_IDENTIFICATION_ERROR, uploadError.message);
      }
    }

    let fileNameAddress;
    const addressFileInput = whitelabelConfig.functionality.identificationRequest.worldInputs.addressConfirmationFile;

    if (addressFileInput) {
      const { addressConfirmationFile } = data;

      // @ts-ignore
      fileNameAddress = `investors/${state.user!.id}/address.${getExtension(addressConfirmationFile.type)}`;
      const storageRef = storage.ref().child(fileNameAddress);

      // @ts-ignore
      const [uploadError] = await to(storageRef.put(addressConfirmationFile));

      if (uploadError) {
        return commit(MUTATIONS.SEND_PRIVATE_IDENTIFICATION_ERROR, uploadError.message);
      }
    }

    const [saveToDbError] = await to(
      functions.httpsCallable('createIdentificationRequest')({
        ...data,
        ...(uploadPassport ? { passport: fileNamePassport } : {}),
        ...(addressFileInput ? { addressConfirmationFile: fileNameAddress } : {}),
      }),
    );

    if (saveToDbError) {
      return commit(MUTATIONS.SEND_PRIVATE_IDENTIFICATION_ERROR, saveToDbError.message);
    }

    try {
      // @ts-ignore
      this._vm.$gtm?.trackEvent({
        event: 'verification_started',
        type: 'PrivateIdentification',
        email: state.user!.email,
        first_name: data.name,
        last_name: data.surname,
        phone: data.telephone,
        birthdate: data.dateOfBirth,
        adress: data.streetAddress,
        housenumber: data.houseNumber,
        postcode: data.postalCode,
        country: data.country,
      });
    } catch (e) { /* Silent error */ }

    return commit(MUTATIONS.SEND_PRIVATE_IDENTIFICATION_SUCCESS);
  },

  async sendBusinessIdentification(
    { commit, state }: ActionContext<State, State>,
    data: BusinessIdentification,
  ): Promise<void> {
    commit(MUTATIONS.SEND_BUSINESS_IDENTIFICATION_PROCESSING);

    const user = auth.currentUser as firebase.User;

    const { passport, dateExcerpt, kvkImage } = data;

    const dateExcerptFileInput = whitelabelConfig.functionality.identificationRequest.businessInputs.dateExcerpt;

    const filePaths = {};
    const storageRef = storage.ref();

    const fileHandler = async (file: File, path: 'passport' | 'kvkImage' | 'dateExcerpt'): Promise<void> => {
      if (file) {
        const filePath = `investors/${state.user!.id}/${path}.${getExtension(file.type)}`;
        const fileRef = storageRef.child(filePath);
        filePaths[path] = filePath;
        await fileRef.put(file);
      }
    };
    try {
      if (uploadPassport && passport) {
        fileHandler(passport as unknown as File, 'passport');
      }
      if (dateExcerptFileInput && dateExcerpt) {
        fileHandler(dateExcerpt as unknown as File, 'dateExcerpt');
      }
      if (kvkImage) {
        fileHandler(kvkImage as unknown as File, 'kvkImage');
      }
    } catch (uploadError) {
      return commit(MUTATIONS.SEND_PRIVATE_IDENTIFICATION_ERROR, (uploadError as Error).message);
    }

    const [saveToDbError] = await to(
      functions.httpsCallable('createIdentificationRequest')({
        ...data,
        ...filePaths,
      }),
    );

    if (saveToDbError) {
      return commit(MUTATIONS.SEND_BUSINESS_IDENTIFICATION_ERROR, saveToDbError.message);
    }

    try {
      // @ts-ignore
      this._vm.$gtm?.trackEvent({
        event: 'verification_started',
        type: 'BusinessIdentification',
        email: user.email,
        first_name: data.name,
        last_name: data.surname,
        phone: data.telephone,
        birthdate: data.dateOfBirth,
        adress: data.streetAddress,
        housenumber: data.houseNumber,
        postcode: data.postalCode,
        country: data.country,
        companyId: data.companyId,
        kvk: data.kvk,
      });
    } catch (e) { /* Silent error */ }

    return commit(MUTATIONS.SEND_BUSINESS_IDENTIFICATION_SUCCESS);
  },

  async sendItsmeIdentification(
    { commit, state }: ActionContext<State, State>,
    data: ItsmeIdentification,
  ): Promise<void> {
    commit(MUTATIONS.SEND_ITSME_IDENTIFICATION_PROCESSING);

    const [itsmeError, itsmeSuccess] = await to(
      functions.httpsCallable('itsmeDataGather')(data),
    );

    if (itsmeError) {
      return commit(MUTATIONS.SEND_ITSME_IDENTIFICATION_ERROR, itsmeError.message);
    }

    if (itsmeSuccess && !itsmeSuccess.data.success) {
      return commit(MUTATIONS.SEND_ITSME_IDENTIFICATION_ERROR, itsmeSuccess.data.error);
    }

    try {
      // @ts-ignore
      this._vm.$gtm?.trackEvent({
        event: 'verification_started',
        type: 'ItsmeIdentification',
        ...data,
      });
    } catch (e) { /* Silent error */ }

    return commit(MUTATIONS.SEND_ITSME_IDENTIFICATION_SUCCESS);
  },

  async changeBankAccount(
    { commit }: ActionContext<State, State>,
    bankAccount: string,
  ): Promise<void> {
    commit(MUTATIONS.CHANGE_BANK_ACCOUNT_EMAIL_PROCESSING);

    // ToDo: check if this is necessary.
    await Vue.nextTick();

    const [changeBankAccountEmailError] = await to(
      functions.httpsCallable('bankAccountChange')({ bankAccount }),
    );

    if (changeBankAccountEmailError) {
      return commit(MUTATIONS.CHANGE_BANK_ACCOUNT_EMAIL_ERROR, changeBankAccountEmailError.message);
    }

    return commit(MUTATIONS.CHANGE_BANK_ACCOUNT_EMAIL_SUCCESS);
  },

  async confirmBankAccountChange(
    { commit }: ActionContext<State, State>,
    id: string,
  ): Promise<void> {
    commit(MUTATIONS.CHANGE_BANK_ACCOUNT_PROCESSING);

    const [updateDataError] = await to(
      functions.httpsCallable('bankAccountUpdate')({ id }),
    );

    if (updateDataError) {
      return commit(MUTATIONS.CHANGE_BANK_ACCOUNT_ERROR, 'There was a problem updating the bank account number.');
    }

    return commit(MUTATIONS.CHANGE_BANK_ACCOUNT_SUCCESS);
  },

  async emailVerification(
    { commit, state }: ActionContext<State, State>,
    { actionCode, mode, email }: { actionCode: string, mode: string, email: string },
  ): Promise<void> {
    commit(MUTATIONS.EMAIL_VERIFICATION_PROCESSING);

    if (mode === 'verifyAndChangeEmail') {
      const [transactionError] = await to(
        db.runTransaction(async (transaction): Promise<void> => {
          const [_, getInvestorQuerySuccess] = await to(db.collection('investors').where('newPendingEmail', '==', email).limit(1).get());

          if (getInvestorQuerySuccess && getInvestorQuerySuccess.docs && getInvestorQuerySuccess.docs.length > 0) {
            const investorRef = getInvestorQuerySuccess.docs[0].ref;

            transaction.update(investorRef, { email, newPendingEmail: firebase.firestore.FieldValue.delete() });

            const [verifyError] = await to(auth.applyActionCode(actionCode));
            if (verifyError) {
              throw verifyError;
            }
          } else {
            throw Error('Error verifying the email.');
          }
        }),
      );

      if (transactionError) {
        return commit(MUTATIONS.EMAIL_VERIFICATION_ERROR, transactionError.message);
      }

      try {
        // @ts-ignore
        this._vm.$gtm?.trackEvent({
          event: 'email_verification_completed',
          email: state.user?.email,
        });
      } catch (e) { /* Silent error */ }

      return commit(MUTATIONS.EMAIL_VERIFICATION_SUCCESS);
    }

    const [verifyError, verifySuccess] = await to(auth.applyActionCode(actionCode));

    if (verifyError) {
      return commit(MUTATIONS.EMAIL_VERIFICATION_ERROR, verifyError.message);
    }

    try {
      // @ts-ignore
      this._vm.$gtm?.trackEvent({
        event: 'signup_completed',
        email: state.user?.email,
      });
    } catch (e) { /* Silent error */ }

    return commit(MUTATIONS.EMAIL_VERIFICATION_SUCCESS, verifySuccess);
  },

  async resetPassword(
    { commit }: ActionContext<State, State>,
    { actionCode, newPassword }: { actionCode: string, newPassword: string },
  ): Promise<void> {
    commit(MUTATIONS.CONFIRM_PASSWORD_RESET_PROCESSING);

    const [confirmError, confirmSuccess] = await to(auth.confirmPasswordReset(actionCode, newPassword));
    if (confirmError) {
      return commit(MUTATIONS.CONFIRM_PASSWORD_RESET_ERROR, confirmError.message);
    }

    return commit(MUTATIONS.CONFIRM_PASSWORD_RESET_SUCCESS, confirmSuccess);
  },

  async recoverEmail(
    { commit }: ActionContext<State, State>,
    { actionCode }: { actionCode: string },
  ): Promise<void> {
    commit(MUTATIONS.RECOVER_EMAIL_PROCESSING);

    const [confirmError, confirmSuccess] = await to(auth.applyActionCode(actionCode));
    if (confirmError) {
      commit(MUTATIONS.RECOVER_EMAIL_ERROR, confirmError.message);
    }

    return commit(MUTATIONS.RECOVER_EMAIL_SUCCESS, confirmSuccess);
  },

  async revertSecondFactor(
    { commit }: ActionContext<State, State>,
    { actionCode }: { actionCode: string },
  ): Promise<void> {
    commit(MUTATIONS.REVERT_SECOND_FACTOR_PROCESSING);

    const [confirmError, confirmSuccess] = await to(auth.applyActionCode(actionCode));

    if (confirmError) {
      return commit(MUTATIONS.REVERT_SECOND_FACTOR_ERROR, confirmError.message);
    }

    return commit(MUTATIONS.REVERT_SECOND_FACTOR_SUCCESS, confirmSuccess);
  },

  async checkActionCode(
    { commit }: ActionContext<State, State>,
    { actionCode }: { actionCode: string },
  ): Promise<void> {
    commit(MUTATIONS.CHECK_ACTION_CODE_PROCESSING);

    const [confirmError, confirmSuccess] = await to(auth.checkActionCode(actionCode));
    if (confirmError) {
      commit(MUTATIONS.CHECK_ACTION_CODE_ERROR, confirmError.message);
    }

    return commit(MUTATIONS.CHECK_ACTION_CODE_SUCCESS, confirmSuccess);
  },

  activateInitialTooltip({ commit }: ActionContext<State, State>): void {
    commit(MUTATIONS.SHOW_INITIAL_TOOLTIP);

    setTimeout((): void => {
      commit(MUTATIONS.HIDE_INITIAL_TOOLTIP);
    }, 6000);
  },

  async createDataChangeRequest(
    { commit, state }: ActionContext<State, State>,
    { type, newData, previousData }: DataChangeRequest,
  ): Promise<void> {
    if (type === 'bankAccount') {
      commit(MUTATIONS.CHANGE_BANK_ACCOUNT_PROCESSING);
    } else {
      commit(MUTATIONS.CREATE_DATA_CHANGE_REQUEST_PROCESSING);
    }

    const investor = db.collection('investors').doc(state.user?.id);

    const [getBankAccountDataChangeError, getDataChangeRequestSuccess] = await to(
      db
        .collection('dataChangeRequests')
        .where('investor', '==', investor)
        .where('type', '==', type)
        .where('status', '==', 'pending')
        .get(),
    );

    if (getBankAccountDataChangeError || getDataChangeRequestSuccess?.docs.length) {
      if (type === 'bankAccount') {
        commit(MUTATIONS.CHANGE_BANK_ACCOUNT_ERROR, getBankAccountDataChangeError?.message || 'Pending data change request for this type already exists.');
      } else {
        commit(MUTATIONS.CREATE_DATA_CHANGE_REQUEST_ERROR, getBankAccountDataChangeError?.message || 'Pending data change request for this type already exists.');
      }
    } else {
      const previousDataIsValid = Object.values(previousData).every((val): boolean => !!val);

      const [createDataChangeError] = await to(
        db
          .collection('dataChangeRequests')
          .add({
            investor,
            type,
            newData,
            ...previousDataIsValid && {
              previousData,
            },
            status: 'pending',
          }),
      );

      if (createDataChangeError) {
        if (type === 'bankAccount') {
          commit(MUTATIONS.CHANGE_BANK_ACCOUNT_ERROR, createDataChangeError.message);
        } else {
          commit(MUTATIONS.CREATE_DATA_CHANGE_REQUEST_ERROR, createDataChangeError.message);
        }
      }

      if (type === 'bankAccount') {
        commit(MUTATIONS.CHANGE_BANK_ACCOUNT_SUCCESS);
      } else {
        commit(MUTATIONS.CREATE_DATA_CHANGE_REQUEST_SUCCESS);
      }
      commit(MUTATIONS.SEND_DATA_CHANGE_NOTIFICATION_PROCESSING);

      const [sendEmailError] = await to(
        functions.httpsCallable('sendDataChangeNotificationEmail')({
        }),
      );

      if (sendEmailError) {
        commit(MUTATIONS.SEND_DATA_CHANGE_NOTIFICATION_ERROR);
      }
      commit(MUTATIONS.SEND_DATA_CHANGE_NOTIFICATION_SUCCESS);
    }
  },

  async assetReservation(
    { commit }: ActionContext<State, State>,
    { name, email, asset, telephone, amount }: AssetReservation,
  ): Promise<void> {
    commit(MUTATIONS.ASSET_RESERVATION_PROCESSING);

    const [assetReservationError] = await to(
      functions.httpsCallable('assetReservation')({ name, email, asset, telephone, amount }),
    );

    if (assetReservationError) {
      return commit(MUTATIONS.ASSET_RESERVATION_ERROR, 'There was a problem submitting reservation to the asset.');
    }

    return commit(MUTATIONS.ASSET_RESERVATION_SUCCESS);
  },
};
