import firebase from 'firebase/app';

/**
 * Describing the iR status.
 */
export enum IdentificationRequestStatus {
  Initial = 'initial',
  Approved = 'approved',
  Rejected = 'rejected',
}

/**
 * Efficient human gender enum.
 */
export enum UserGender {
  Male = 'm',
  Female = 'f',
}

export enum UserProfession {
  AccommodationAndFoodServices = 'accommodationAndFoodServices',
  AdministrationBusinessSupportAndWasteManagementServices = 'administrationBusinessSupportAndWasteManagementServices',
  AdultEntertainmentAndDatingServices = 'adultEntertainmentAndDatingServices',
  AdvisoryAndFinancialServices = 'advisoryAndFinancialServices',
  AgricultureForestryFishingAndHunting = 'agricultureForestryFishingAndHunting',
  ArmsDealing = 'armsDealing',
  ArtsEntertainmentAndRecreation = 'artsEntertainmentAndRecreation',
  BusinessFranchises = 'businessFranchises',
  CarbonAndMining = 'carbonAndMining',
  CasinosOrOnlineGaming = 'casinosOrOnlineGaming',
  Construction = 'construction',
  ConsumerGoodsAndServices = 'consumerGoodsAndServices',
  Cryptocurrency = 'cryptocurrency',
  EducationalServices = 'educationalServices',
  FinanceAndInsurance = 'financeAndInsurance',
  HealthcareAndSocialAssistance = 'healthcareAndSocialAssistance',
  IndustrialMachineryGasAndChemicals = 'industrialMachineryGasAndChemicals',
  Information = 'information',
  LifeSciences = 'lifeSciences',
  Manufacturing = 'manufacturing',
  OnlineRetail = 'onlineRetail',
  OtherServicesExceptPublicAdministration = 'otherServicesExceptPublicAdministration',
  ProfessionalScientificAndTechnicalServices = 'professionalScientificAndTechnicalServices',
  RealEstateAndRentalAndLeasing = 'realEstateAndRentalAndLeasing',
  RetailMarketReports = 'retailMarketReports',
  RetailTrade = 'retailTrade',
  SpecialistEngineeringInfrastructureAndContractors = 'specialistEngineeringInfrastructureAndContractors',
  Technology = 'technology',
  TransportationAndWarehousing = 'transportationAndWarehousing',
  Utilities = 'utilities',
  WholesaleTrade = 'wholesaleTrade',
}

export enum UserEmployment {
  EmployedOtherThanBoardMemberSeniorExecutive = 'employedOtherThanBoardMemberSeniorExecutive',
  EmployedBoardMember = 'employedBoardMember',
  EmployedSeniorExecutive = 'employedSeniorExecutive',
  SelfEmployedBusinessOwner = 'selfEmployedBusinessOwner',
  Unemployed = 'unemployed',
}

export enum UserSourceOfFunds {
  EmploymentIncome = 'employmentIncome',
  Savings = 'savings',
  Heritage = 'heritage',
  ProceedsFromInvestments = 'proceedsFromInvestments',
  ProceedsFromProperty = 'proceedsFromProperty',
  ProceedsFromBusiness = 'proceedsFromBusiness',
  Other = 'other',
}

export enum UserExperience {
  Experienced = 'a',
  Unexperienced = 'b',
}

/**
 * Basic iR interface.
 */
export type PrivateIdentification = {
  id?: string;
  bankAccount: string;
  bankAccountOwner?: string;
  city: string;
  country: string;
  dateOfBirth: firebase.firestore.Timestamp;
  dateOfBirthPartner?: firebase.firestore.Timestamp;
  passportExpirationDate?: firebase.firestore.Timestamp;
  gender: UserGender;
  houseNumber: string;
  message?: string;
  name: string;
  middleName?: string;
  initials?: string;
  nationality: string;
  passport?: string;
  addressConfirmationFile?: string;
  passportSecond?: string;
  postalCode: string;
  riskClass?: string;
  status: IdentificationRequestStatus;
  streetAddress: string;
  surname: string;
  telephone: string;
  deleted: boolean;
  userExperience?: UserExperience;
  createdDateTime: firebase.firestore.Timestamp;
  usResidency?: boolean;
  professionalSector?: UserProfession;
  employmentStatus?: UserEmployment;
  employerName?: string;
  roleDescription?: string;
  sourceOfFunds?: UserSourceOfFunds;
  receivePhysicalMailings?: boolean;
  countryTax?: string;
  isTaxPayer?: boolean;
  sameDepositaryReceiptsHolder?: boolean;
  sameDepositaryReceiptsHolderCustomAnswer?: string;
  isPoliticallyExposed?: boolean;
  personallyPep?: boolean;
  pepFunction?: string;
  pepRelationship?: string;
  pepRelationshipFirstName?: string;
  pepRelationshipMiddleName?: string;
  pepRelationshipLastName?: string;
  pepRelationshipAddress?: string;
  pepRelationshipPostcode?: string;
  pepRelationshipCountry?: string;
  pepRelationshipFunction?: string;
  isProfessionalInvestor?: boolean;
  taxPayerNumber?: number;
  socialSecurityNumber?: number;
  subscribeNewsLetter?: boolean;
  confirmCorrectInformation?: boolean;
  updatedDateTime: firebase.firestore.Timestamp;
}

/**
 * BusinessIdentification type.
 */
export interface BusinessIdentification extends PrivateIdentification {
  companyId: string;
  kvk: number;
  kvkImage?: string;
  dateExcerpt?: string;
}

/**
 * ItsmeIdentification type.
 */
export interface ItsmeIdentification {
  authCode: string;
  redirectUrl: string;
  investorId: string
}

export type IdentificationRequest = PrivateIdentification | BusinessIdentification;

/**
 * Type guard to check the type of Identification Request.
 * @param iRequest basic Identification Request interfaces.
 */
export const isBusiness = (iRequest: IdentificationRequest): iRequest is BusinessIdentification => !!(iRequest as BusinessIdentification)?.kvk;
