import { Module } from 'vuex';
import { firebase } from '@/firebase';
import { State } from '@/store/models';
import { AssetEarning } from '@/store/models/asset';

export default <Module<AssetEarning[], State>>{
  state: [],
  mutations: {
  },
  actions: {},
  getters: {
    getEarningsByAsset: (state): Function =>
    (assetId: string): AssetEarning[] | [] => {
      const assetEarnings = state.filter((assetEarning): boolean => assetEarning.asset.id === assetId);
      if (assetEarnings) return assetEarnings;
      return [];
    },
  },
};
