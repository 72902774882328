/**
 * Client configs.
 */

module.exports = {
  mobilePhoneApp: false,
  currency: '$',
  logo: 'mar/mar.svg',
  logoAlt: 'mar/mar-alt.svg',
  logoShare: 'bloqhouse.png',
  logoPremium: 'mar-premium.svg',
  location: 'EUROPE-WEST1',
  internalClientName: 'mar',
  iconName1: 'favicon.ico',
  iconName2: 'favicon.svg',
  iconName3: 'favicon-512.png',
  scssVariables: './src/scss/variables/brands/_mar.scss',
  name: 'Marula',
  phone: '',
  email: 'vince@bloqhouse.com',
  website: 'https://marulasquare.com/',
  glossary: true,
  langFileName: 'mar/language-config.ts',
  paymentServiceProvider: 'Custom',
  bloqhouseBrandingEnabled: true,
  pescheckEnabled: false,
  modules: {
    landing: false,
    identification: {
      default: true, // setting this to false requires manual changes,
      itsme: false,
    },
  },
  loginRedirect: '/account',
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  assetValuationsEnabled: false,
  functionality: {
    idinGlobalSwitch: false,
    containerGlobalSwitch: false,
    progressEndpoint: false,
    scheduleEndPayment: false,
    scheduleEnding: false,
    sharesReserved: false,
    loanEmail: false,
    questionnaire: {
      folderName: 'default',
    },
    checkout: {
      initCheckoutStore: 'default',
      stylingFolderName: 'default',
      remoteCheckoutEnabled: false,
      steps: ['Questionnaire', 'Identification', 'Investment', 'Terms', 'Payment'],
      resetCheckoutExceptWhenAt: ['Terms', 'Questionnaire'],
      enableCheckoutQuestionnaire: false,
      checkoutInvestment: {
        folderName: 'default',
        firstParagraphEnabled: false,
        showMinimumAmount: true,
        progressBar: false,
      },
      checkoutLegal: {
        enablePoint4: false,
        enableLegalDoc: true,
        footerButtonText: 'checkout.investment.nextStep',
        footerButtonNoteText: 'checkout.legal.continue',
      },
      checkoutFooter: {
        rowJustifyContentEnabled: false,
        checkoutUpdateType: 'default',
      },
      checkoutQuestionnaire: {
        folderName: 'default',
      },
      checkoutRecap: {
        finalisingPaymentTextEnabled: true,
      },
      checkoutStatus: {
        statusEnabled: true,
        titleEnabled: true,
        navigateToProjectButtonEnabled: true,
        showButtons: true,
      },
    },
    paymentInitTransaction: {
      loanTypeFundMax: false,
      containsLegalTerms: false,
      paumentInit: {
        fundTypeEnabled: false,
        signatureChoiceEnabled: false,
        assetValuationIdEnabled: false,
        legalDocsEnabled: false,
        questionaireQuestionAndAnswersEnabled: false,
        knowledgeQuestionAndAnswersEnabled: false,
      },
    },
    generateDividendsEarnings: 'generateDividendsPDF',
    headerForPDF: false,
    customSignUpGreeting: '',
    index: {
      preloadHref: '"https://api.fonts.coollabs.io/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap"',
      stylesheetHref: '"https://api.fonts.coollabs.io/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap"',
    },
    dividendsFormat: false,
    dividendsReturnsOptions: {
      checkIfAssetHasFixedDividends: true,
    },
    bannerLegal: {
      requireLegalBanner: true,
      phoneNumber: false,
      legalBannerNoRequiresLicense: true,
      legalBannerPosition: 'top',
    },
    navbar: {
      boxedEnabled: true,
      languageChoice: {
        enableLanguageChoice: true,
        languages: ['nl', 'en'],
        defaultLanguage: 'en',
      },
      externalLink: 'https://marulasquare.com/',
      backgroundPrimary: false,
      containerEnabled: false,
      shadowEnabled: true,
      authPageRedirect: 'login',
      customNonLocalNonAuthLogin: false,
      logoSize: 'small',
      englishLanguageEnabled: true,
      showDropDownMenu: true,
      navbarLinks: {
        knowledge: {
          type: 'routerLink',
          visible: true,
        },
        home: {
          type: 'anchor',
          visible: true,
        },
        account: {
          visible: false,
        },
      },
    },
    accountDashboard: {
      view: 'standard',
      holdingsImagePath: '@/assets/illustrations/my-funds.svg',
      pendingPaymentsInfoboxEnabled: false,
    },
    accountBalance: {
      customImage: true,
      customWrapperClass: 'account__balance__combined',
      animationsEnabled: false,
    },
    accountInvestments: {
      transactionAmountWrapperClassName: 'account__transactions__list__item__container--img-name',
      transactionAmountLastColumnClassName: 'account__transactions__list__item__payments__item__method',
      boughtSharesExcludeLoan: false,
      checkForPremium: true,
    },
    accountSingleFund: {
      availableProjectsTotal: 'available',
      textCheckFundType: false,
      displayInterest: false,
      overlayClassEnabled: false,
      firstWithDownloadVersion: 'assets',
      totalsSectionEnabled: true,
      totalsSectionTwoEnabled: false,
      totalsSource: 'investment',
      dividendsSource: 'dividends',
      netValueCheckIfNoInvestments: false,
      includeIcon: false,
      completedBoxTextClassEnabled: true,
      premiumClassEnabled: true,
      projectHistoryEnabled: true,
      totalEarningsEnabled: true,
      totalCostsEnabled: true,
      projectHistoryTabs: ['earnings', 'costs'],
    },
    property: {
      secondaryDocUnitedStates: true,
      secondaryDocThreshold: false,
      enableVentureDetails: true,
      riskProperty: false,
      availableNumbersEnabled: true,
      showInterestEnabled: false,
      showContactAddress: false,
      transformedDateTimeEnabled: false,
    },
    propertiesGridFund: {
      disableDocsIfNotInvested: false,
      contentsCardEnabled: false,
      subtitle: 'assetDescription',
      premiumFundDividendClassName: 'properties__premium-fund',
    },
    properties: {
      toolbarSearchEnabled: false,
      dateSortingProperty: 'createdDateTime',
      titleEnabled: true,
    },
    identificationRequest: {
      dateOfBirthFormat: 'DD/MM/YYYY',
      uploadPassport: true,
      businessRiskClassProp: false,
      businessExperienceProp: false,
      worldRiskClassProp: false,
      worldExperienceProp: false,
      businessInputs: {
        companyName: {
          inputName: 'companyName',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.companyName',
          inputType: 'formInput',
        },
        legalStatus: {
          inputName: 'legalStatus',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.legalStatus',
          inputType: 'radio',
          displayFlex: false,
          radioOptions: [
            {
              label: 'identification.individual',
              optionValue: 'individual',
            },
            {
              label: 'identification.foundation',
              optionValue: 'foundation',
            },
            {
              label: 'identification.cooperative',
              optionValue: 'cooperative',
            },
            {
              label: 'identification.company',
              optionValue: 'company',
            },
            {
              label: 'identification.limitedPartnership',
              optionValue: 'limitedPartnership',
            },
            {
              label: 'identification.other',
              optionValue: 'other',
            },
          ],
          radioCustomInputCondition: {
            inputName: 'legalStatusCustomAnswer',
            expectedValue: 'other',
          },
        },
        kvkNumber: {
          inputName: 'kvkNumber',
          rules: 'required|integer',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.business.kvkNumber',
          inputType: 'formInput',
        },
        kvkImage: {
          inputName: 'kvkImage',
          rules: '',
          class: 'col--12 upload__passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.business.uploadYourKvKImage',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        middleName: {
          inputName: 'middleName',
          rules: 'alpha_spaces|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.middleName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: 'tel',
          label: 'common.telephone',
          inputType: 'formInput',
        },
        dateOfBirth: {
          inputName: 'dateOfBirth',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.dateOfBirth',
        },
        bankAccountOwner: {
          inputName: 'bankAccountOwner',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.bankAccountOwner',
          inputType: 'formInput',
        },
        bankAccount: {
          inputName: 'bankAccount',
          rules: 'required|alpha_num|max:40|min:18',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.iban',
          inputType: 'formInput',
        },
        professionalSector: {
          inputName: 'professionalSector',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'professionalOptions',
          label: 'identification.professionalSector',
        },
        employmentStatus: {
          inputName: 'employmentStatus',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'employmentOptions',
          label: 'identification.employmentStatus',
        },
        employerName: {
          inputName: 'employerName',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.employerName',
          inputType: 'formInput',
        },
        roleDescription: {
          inputName: 'roleDescription',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.roleDescription',
          inputType: 'formInput',
        },
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.address',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.houseNumber',
          inputType: 'formInput',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
        countryTax: {
          inputName: 'countryTax',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.countryTax',
        },
        isTaxPayer: {
          inputName: 'isTaxPayer',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.taxStatus',
          inputType: 'radio',
          displayFlex: true,
          radioOptions: [
            {
              label: 'identification.taxPayer',
              optionValue: true,
            },
            {
              label: 'identification.taxExempt',
              optionValue: false,
            },
          ],
        },
        taxPayerNumber: {
          inputName: 'taxPayerNumber',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.taxPayerNumber',
          inputType: 'formInput',
        },
        socialSecurityNumber: {
          inputName: 'socialSecurityNumber',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.socialSecurityNumber',
          inputType: 'formInput',
        },
        sameDepositaryReceiptsHolder: {
          inputName: 'sameDepositaryReceiptsHolder',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.depositary',
          inputType: 'radio',
          displayFlex: true,
          radioOptions: [
            {
              label: 'identification.itself',
              optionValue: true,
            },
            {
              label: 'identification.anotherPerson',
              optionValue: false,
            },
          ],
          radioCustomInputCondition: {
            inputName: 'sameDepositaryReceiptsHolderCustomAnswer',
            expectedValue: false,
          },
        },
        isProfessionalInvestor: {
          inputName: 'isProfessionalInvestor',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.professionalInvestor',
          inputType: 'radio',
          displayFlex: true,
          radioOptions: [
            {
              label: 'common.yesRadio',
              optionValue: true,
            },
            {
              label: 'common.noRadio',
              optionValue: false,
            },
          ],
        },
        isPoliticallyExposed: {
          inputName: 'isPoliticallyExposed',
          rules: 'required',
          class: '',
          autocomplete: false,
          type: '',
          label: 'settings.details.identification.politicallyExposed',
          inputType: 'radio',
          displayFlex: true,
          radioOptions: [
            {
              label: 'common.yesRadio',
              optionValue: true,
            },
            {
              label: 'common.noRadio',
              optionValue: false,
            },
          ],
        },
        personallyPep: {
          inputName: 'personallyPep',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.personallyPep',
          inputType: 'radio',
          displayFlex: false,
          radioOptions: [
            {
              label: 'common.yesRadio',
              optionValue: true,
            },
            {
              label: 'common.noRadio',
              optionValue: false,
            },
          ],
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
          ],
        },
        pepFunction: {
          inputName: 'pepFunction',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepFunction',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: true,
            },
          ],
        },
        pepRelationship: {
          inputName: 'pepRelationship',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationship',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipFirstName: {
          inputName: 'pepRelationshipFirstName',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipFirstName',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipMiddleName: {
          inputName: 'pepRelationshipMiddleName',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipMiddleName',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipLastName: {
          inputName: 'pepRelationshipLastName',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipLastName',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipAddress: {
          inputName: 'pepRelationshipAddress',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipAddress',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipPostcode: {
          inputName: 'pepRelationshipPostcode',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipPostcode',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipCountry: {
          inputName: 'pepRelationshipCountry',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipCountry',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipFunction: {
          inputName: 'pepRelationshipFunction',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipFunction',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        sourceOfFunds: {
          inputName: 'sourceOfFunds',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'sourceOfFundsOptions',
          label: 'identification.sourceOfFunds',
          selectCustomInputCondition: {
            inputName: 'sourceOfFundsCustomAnswer',
            expectedValue: 'other',
          },
        },
        usResidency: {
          inputName: 'usResidency',
          rules: '',
          class: 'checkbox',
          autocomplete: false,
          type: '',
          inputType: 'checkbox',
          label: 'common.usCitizen',
        },
        passport: {
          inputName: 'passport',
          rules: '',
          class: 'col--12 upload__passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.world.uploadYourPassport',
        },
        passportExpirationDate: {
          inputName: 'passportExpirationDate',
          rules: 'required',
          class: 'col--12',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.passportExpirationDate',
        },
        dateExcerpt: {
          inputName: 'dateExcerpt',
          rules: '',
          class: 'col--12 upload__passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.world.uploadDateExcerpt',
        },
        isUBO: {
          inputName: 'isUBO',
          rules: 'required',
          class: 'checkbox',
          autocomplete: false,
          type: '',
          inputType: 'checkbox',
          label: 'identification.investorIsUBO',
        },
        confirmCorrectInformation: {
          inputName: 'confirmCorrectInformation',
          rules: 'required',
          class: 'checkbox',
          autocomplete: false,
          type: '',
          inputType: 'checkbox',
          label: 'identification.confirmCorrectInformation',
        },
      },
      worldInputs: {
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        middleName: {
          inputName: 'middleName',
          rules: 'alpha_spaces|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.middleName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: 'tel',
          label: 'common.telephone',
          inputType: 'formInput',
        },
        dateOfBirth: {
          inputName: 'dateOfBirth',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.dateOfBirth',
        },
        bankAccountOwner: {
          inputName: 'bankAccountOwner',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.bankAccountOwner',
          inputType: 'formInput',
        },
        bankAccount: {
          inputName: 'bankAccount',
          rules: 'required|alpha_num|max:40|min:18',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.iban',
          inputType: 'formInput',
        },
        professionalSector: {
          inputName: 'professionalSector',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'professionalOptions',
          label: 'identification.professionalSector',
        },
        employmentStatus: {
          inputName: 'employmentStatus',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'employmentOptions',
          label: 'identification.employmentStatus',
        },
        employerName: {
          inputName: 'employerName',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.employerName',
          inputType: 'formInput',
        },
        roleDescription: {
          inputName: 'roleDescription',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.roleDescription',
          inputType: 'formInput',
        },
        addressConfirmationFile: {
          inputName: 'addressConfirmationFile',
          rules: '',
          class: 'col--12 upload__passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.bankStatement',
        },
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.address',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.houseNumber',
          inputType: 'formInput',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
        countryTax: {
          inputName: 'countryTax',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.countryTax',
        },
        isTaxPayer: {
          inputName: 'isTaxPayer',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.taxStatus',
          inputType: 'radio',
          displayFlex: true,
          radioOptions: [
            {
              label: 'identification.taxPayer',
              optionValue: true,
            },
            {
              label: 'identification.taxExempt',
              optionValue: false,
            },
          ],
        },
        taxPayerNumber: {
          inputName: 'taxPayerNumber',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.taxPayerNumber',
          inputType: 'formInput',
        },
        socialSecurityNumber: {
          inputName: 'socialSecurityNumber',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.socialSecurityNumber',
          inputType: 'formInput',
        },
        sameDepositaryReceiptsHolder: {
          inputName: 'sameDepositaryReceiptsHolder',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.depositary',
          inputType: 'radio',
          displayFlex: true,
          radioOptions: [
            {
              label: 'identification.itself',
              optionValue: true,
            },
            {
              label: 'identification.anotherPerson',
              optionValue: false,
            },
          ],
          radioCustomInputCondition: {
            inputName: 'sameDepositaryReceiptsHolderCustomAnswer',
            expectedValue: false,
          },
        },
        isProfessionalInvestor: {
          inputName: 'isProfessionalInvestor',
          rules: 'required',
          class: '',
          autocomplete: false,
          type: '',
          label: 'identification.professionalInvestor',
          inputType: 'radio',
          displayFlex: true,
          radioOptions: [
            {
              label: 'common.yesRadio',
              optionValue: true,
            },
            {
              label: 'common.noRadio',
              optionValue: false,
            },
          ],
        },
        isPoliticallyExposed: {
          inputName: 'isPoliticallyExposed',
          rules: 'required',
          class: '',
          autocomplete: false,
          type: '',
          label: 'identification.politicallyExposed',
          inputType: 'radio',
          displayFlex: true,
          radioOptions: [
            {
              label: 'common.yesRadio',
              optionValue: true,
            },
            {
              label: 'common.noRadio',
              optionValue: false,
            },
          ],
        },
        personallyPep: {
          inputName: 'personallyPep',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.personallyPep',
          inputType: 'radio',
          displayFlex: false,
          radioOptions: [
            {
              label: 'common.yesRadio',
              optionValue: true,
            },
            {
              label: 'common.noRadio',
              optionValue: false,
            },
          ],
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
          ],
        },
        pepFunction: {
          inputName: 'pepFunction',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepFunction',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: true,
            },
          ],
        },
        pepRelationship: {
          inputName: 'pepRelationship',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationship',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipFirstName: {
          inputName: 'pepRelationshipFirstName',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipFirstName',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipMiddleName: {
          inputName: 'pepRelationshipMiddleName',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipMiddleName',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipLastName: {
          inputName: 'pepRelationshipLastName',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipLastName',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipAddress: {
          inputName: 'pepRelationshipAddress',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipAddress',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipPostcode: {
          inputName: 'pepRelationshipPostcode',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipPostcode',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipCountry: {
          inputName: 'pepRelationshipCountry',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipCountry',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        pepRelationshipFunction: {
          inputName: 'pepRelationshipFunction',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.pepRelationshipFunction',
          inputType: 'formInput',
          enabledConditions: [
            {
              inputName: 'isPoliticallyExposed',
              expectedValue: true,
            },
            {
              inputName: 'personallyPep',
              expectedValue: false,
            },
          ],
        },
        sourceOfFunds: {
          inputName: 'sourceOfFunds',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'sourceOfFundsOptions',
          label: 'identification.sourceOfFunds',
          selectCustomInputCondition: {
            inputName: 'sourceOfFundsCustomAnswer',
            expectedValue: 'other',
          },
        },
        usResidency: {
          inputName: 'usResidency',
          rules: '',
          class: 'checkbox',
          autocomplete: false,
          type: '',
          inputType: 'checkbox',
          label: 'common.usCitizen',
        },
        passport: {
          inputName: 'passport',
          rules: '',
          class: 'col--12 upload__passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.world.uploadYourPassport',
        },
        passportExpirationDate: {
          inputName: 'passportExpirationDate',
          rules: 'required',
          class: 'col--12',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.passportExpirationDate',
        },
        subscribeNewsLetter: {
          inputName: 'subscribeNewsLetter',
          rules: '',
          class: 'checkbox',
          autocomplete: false,
          type: '',
          inputType: 'checkbox',
          label: 'common.subscribeNewsLetter',
        },
        confirmCorrectInformation: {
          inputName: 'confirmCorrectInformation',
          rules: 'required',
          class: 'checkbox',
          autocomplete: false,
          type: '',
          inputType: 'checkbox',
          label: 'identification.confirmCorrectInformation',
        },
      },
    },
    identification: {
      questionnaireEnabled: true,
    },
    identificationForm: {
      redirectToAccount: true,
      buttonText: 'dashboard.funds.myPortfolio',
    },
    settings: {
      itemPaddingAdded: false,
      details: 'standard',
      detailsChange: false,
      identification: true,
      settingsAutoReditect: 'identification',
    },
    register: {
      newsletter: false,
      privacyStatement: false,
    },
    authReset: {
      includePleaseCheckInboxInToast: true,
    },
    dropdownMenu: {
      dropdownMenuType: 'standard',
      settingsNavigation: 'settings',
      dropdownMenuItems: {
        portfolio: {
            visible: true,
        },
        settings: {
            visible: true,
        },
      },
    },
    investor: {
      passportPropertyName: 'passport',
    },
  },
  emails: {
    stopIncorrectTemplateIds: true,
    from: 'Marula',
    sharesBought: {
      format: 'full',
      templateId: 'd-eef743b920cc48469557a931ba685c79',
      enabled: true,
      signatureChoiceOnline: '',
      fromAdminTemplateId: '',
      sendlegalDocs: false,
    },
    sharesBoughtAdminNotification: {
      to: 'vince@bloqhouse.com',
      templateId: '',
      enabled: false,
      signatureChoiceOnline: '',
    },
    verifyEmail: {
      templateId: 'd-a4600378b20e4dcda212680fb17829c2',
      format: 'email',
    },
    passwordReset: {
      templateId: 'd-938613cc30e74b268b20bb0ac5451d22',
      format: 'salutationSurname',
    },
    identificationRequest: {
      approved: {
        templateId: 'd-b41b7206923d463ea9eab070bdf88090',
      },
      rejected: {
        templateId: 'd-cc2bf5d89384477a90e179ed3f960e25',
      },
      format: 'salutationSurnameUrl',
    },
    createIdentificationRequest: {
      fromClientName: false,
      toClientName: false,
      toClientEmail: [],
    },
    translations: {
      sharesBoughtInstrument: {
         subject: 'Participaties',
          content: 'Participatiehouder',
      },
    },
    contactSupportByEmail: {
      from: '',
      to: '',
      cc: false,
    },
  },
};
