import firebase from 'firebase/app';
import { Investor } from './user';
import { Asset } from './asset';

/**
 * The upper db object for an Investment.
 * An investments always contains a subcollection of Payments.
 */
export interface Investment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  asset: firebase.firestore.DocumentReference | Asset;
  paidEuroTotal?: number;
  boughtSharesTotal?: number;
  totalEarnings?: number;
  totalRepayments?: number;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  signChoice?: string;
  identifier?: string;
  totalEuroRepayments?: number;
  totalEuroEarnings?: number;
  totalSharesRepayments?: number;
}

/**
 * Defining all the statuses a Payment can have.
 */
export enum PaymentStatus {
  Open = 'open',
  Canceled = 'canceled',
  Pending = 'pending',
  Authorized = 'authorized',
  Expired = 'expired',
  Failed = 'failed',
  Paid = 'paid',
  Requested = 'requested',
  Refund = 'refund',
  PartialRefund = 'partial_refund',
  ChargeBack = 'chargeback',
  Unknown = 'unknown',
}

/**
 * Different payment gateways/providers we use.
 */
export enum PaymentProvider {
  Mollie = 'Mollie',
  PayNL = 'PayNL',
  /**
   * Manual, from Bloqadmin.
   */
  Custom = 'Custom',
}

/**
 * Actual payment info is placed here.
 */
export interface Payment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  asset: firebase.firestore.DocumentReference | Asset;
  dividendsFormat: [string, number];
  provider: PaymentProvider;
  providerData: {
    id: string,
    amount: {
      currency: string,
      value: string | number,
    },
    status: PaymentStatus,
    metadata: {
      uid?: string,
      euroAmount: number,
      sharesAmount: number,
      investmentId?: string,
      assetId?: string,
      paymentId?: string,
      selectedDividendsFormatYear?: [string, number];
    },
    paymentUrl?: string;
    paymentMethod?: string;
    [key: string]: any;
  };
  scheduleEnding?: boolean;
  sharesReserved?: number;
  usedEndPayments?: {
    investmentId: string,
    paymentId: string,
    amount: number,
  }[],
  legalDocuments?: [];
  deleted: boolean;
  ended?: firebase.firestore.Timestamp;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;transactionDate?: firebase.firestore.Timestamp;
}

// Earning subcollection for storing monthly rental payouts
export interface Earning {
  amount: number,
  deleted: boolean,
  period: firebase.firestore.Timestamp,
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  transactionDate?: firebase.firestore.Timestamp;
}

// Repayment subcollection for storing monthly rental payouts
export interface Repayment {
  paymentDate?: any;
  amount: number,
  deleted: boolean,
  period: firebase.firestore.Timestamp,
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  transactionDate?: firebase.firestore.Timestamp;
}

/**
 * Common result interface for all PSP
 */
export interface PaymentResult {
  id: string;
  uid: string;
  /** In Decimal */
  euroAmount: number;
  /** In Decimal */
  euroAmountWithEmissionCost: number;
  sharesAmount: number;
  paymentId: string;
  investmentId: string;
  assetId: string;
  redirectUrl?: string;
  paymentUrl?: string;
  status?: PaymentStatus;
}
