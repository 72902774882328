import { CheckoutStepNames, Step } from '@/store/models/checkout';
import { clientConfig } from '@/helpers/clientData';

const whitelabelConfig = clientConfig();

const { modules, requireContractAgreement, requireQuestionnaire } = whitelabelConfig || {};

const paymentServiceProvider = whitelabelConfig?.paymentServiceProvider;
const stepsConfig = whitelabelConfig?.functionality.checkout.steps;
const enableCheckoutQuestionnaire = whitelabelConfig?.functionality.checkout.enableCheckoutQuestionnaire;
const stepsProcessed: Step[] = [];

export enum IdentCheckoutRouteNames {
  MAIN = 'checkoutIdentificationMain',
  IDIN = 'checkoutIdentificationIdin',
  WORLD = 'checkoutIdentificationWorld',
  BUSINESS = 'checkoutIdentificationBusiness',
}

export const IdentifcationPath = 'identification';
// Loop through stepsConfig and add items to the steps array in the same order as defined in the configs.
stepsConfig.forEach((stepConfig): void => {
  let newStep;

  switch (stepConfig) {
    case 'Questionnaire':
      if (enableCheckoutQuestionnaire || requireQuestionnaire) {
        newStep = {
          name: CheckoutStepNames.Questionnaire,
          route: '/:lang?/checkout/questionnaire/',
          display: 'checkout.questionnaire.questionnaire',
        };
      }
      break;

    case 'Identification':
      if (modules.identification.default) {
        newStep = {
          name: CheckoutStepNames.Identification,
          route: `/:lang?/checkout/${IdentifcationPath}/`,
          display: 'settings.identification.identification',
        };
      }
      break;

    case 'Investment':
      newStep = {
        name: CheckoutStepNames.Investment,
        route: '/:lang?/checkout/investment/',
        display: 'checkout.fund.selectAnAmount',
      };
      break;

    case 'Terms':
      if (requireContractAgreement) {
        newStep = {
          name: CheckoutStepNames.Terms,
          route: '/:lang?/checkout/legal/',
          display: 'checkout.legal.legalAgreement',
        };
      }
      break;
    case 'PaymentMethods':
      newStep = {
        name: CheckoutStepNames.PaymentMethods,
        route: '/:lang?/checkout/payment-methods/',
        display: paymentServiceProvider === 'Custom' ? 'common.reservation' : 'checkout.payment.paymentMethod',
      };
      break;

    case 'Payment':
      newStep = {
        name: CheckoutStepNames.Payment,
        route: '/:lang?/checkout/payment/',
        display: paymentServiceProvider === 'Custom' ? 'common.reservation' : 'common.payment',
      };
      break;

    case 'AmlForm':
      newStep = {
        name: CheckoutStepNames.AmlForm,
        route: '/:lang?/checkout/amlform/',
        display: 'Wwft-formulier',
      };
      break;

    case 'RiskQuestionnaire':
      newStep = {
        name: CheckoutStepNames.RiskQuestionnaire,
        route: '/:lang?/checkout/riskquestionnaire/',
        display: 'checkout.riskquestionnaire.riskquestionnaire',
      };
      break;

    case 'Signature':
      newStep = {
        name: CheckoutStepNames.Signature,
        route: '/:lang?/checkout/signature/',
        display: 'checkout.signing.signature',
      };
      break;

    default:
      throw new Error('Undefined checkout step');
  }

  if (newStep) {
    stepsProcessed.push(newStep);
  }
});

/*
* Steps in the checkout flow
* The order is the same as it is displayed in
* the identification and contract agreement are depended on the config in whitelabel.config
* */
export const steps: Step[] = whitelabelConfig && stepsProcessed;
