/**
 * Default configs to roll back to which prevents CircleCi from erroring out.
 */

module.exports = {
  mobilePhoneApp: false,
  currency: '€',
  logo: 'test',
  logoAlt: 'test',
  logoShare: 'test',
  logoPremium: 'test',
  location: 'EUROPE-WEST1',
  internalClientName: 'default',
  iconName1: '',
  iconName2: '',
  iconName3: '',
  scssVariables: 'test',
  name: 'test',
  phone: 'test',
  email: 'test',
  website: 'test',
  glossary: true,
  langFileName: 'default/language-config.ts',
  paymentServiceProvider: 'Custom',
  bloqhouseBrandingEnabled: true,
  modules: {
    landing: false,
    identification: {
      default: true, // setting this to false requires manual changes,
      itsme: false,
    },
  },
  loginRedirect: '/account',
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  assetValuationsEnabled: false,
  functionality: {
    idinGlobalSwitch: false,
    containerGlobalSwitch: false,
    progressEndpoint: false,
    scheduleEndPayment: false,
    scheduleEnding: false,
    sharesReserved: false,
    loanEmail: false,
    questionnaire: {
      folderName: 'default',
    },
    checkout: {
      initCheckoutStore: 'default',
      stylingFolderName: 'default',
      remoteCheckoutEnabled: false,
      steps: ['Questionnaire', 'Identification', 'Investment', 'Terms', 'Payment'],
      resetCheckoutExceptWhenAt: ['Terms', 'Questionnaire'],
      enableCheckoutQuestionnaire: false,
      checkoutInvestment: {
        folderName: 'default',
        firstParagraphEnabled: false,
        showMinimumAmount: true,
        progressBar: false,
      },
      checkoutLegal: {
        enablePoint4: false,
        enableLegalDoc: false,
        footerButtonText: 'checkout.investment.nextStep',
        footerButtonNoteText: 'checkout.legal.continue',
      },
      checkoutFooter: {
        rowJustifyContentEnabled: false,
        checkoutUpdateType: 'default',
      },
      checkoutQuestionnaire: {
        folderName: 'default',
      },
      checkoutRecap: {
        finalisingPaymentTextEnabled: true,
      },
      checkoutStatus: {
        statusEnabled: true,
        titleEnabled: true,
        navigateToProjectButtonEnabled: true,
        showButtons: true,
      },
    },
    paymentInitTransaction: {
      loanTypeFundMax: false,
      containsLegalTerms: false,
      paumentInit: {
        fundTypeEnabled: false,
        signatureChoiceEnabled: false,
        assetValuationIdEnabled: false,
        legalDocsEnabled: false,
        questionaireQuestionAndAnswersEnabled: false,
        knowledgeQuestionAndAnswersEnabled: false,
      },
    },
    generateDividendsEarnings: 'generateDividendsPDF',
    headerForPDF: false,
    customSignUpGreeting: '',
    index: {
      preloadHref: '"https://api.fonts.coollabs.io/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap"',
      stylesheetHref: '"https://api.fonts.coollabs.io/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap"',
    },
    dividendsFormat: true,
    dividendsReturnsOptions: {
      checkIfAssetHasFixedDividends: true,
    },
    bannerLegal: {
      requireLegalBanner: true,
      legalBannerNoRequiresLicense: true,
      legalBannerPosition: 'top',
      phoneNumber: false,
    },
    navbar: {
      boxedEnabled: true,
      languageChoice: {
        enableLanguageChoice: false,
        languages: ['nl'],
        defaultLanguage: 'nl',
      },
      externalLink: 'test',
      backgroundPrimary: false,
      containerEnabled: false,
      shadowEnabled: true,
      authPageRedirect: 'login',
      customNonLocalNonAuthLogin: false,
      logoSize: 'small',
      englishLanguageEnabled: false,
      showDropDownMenu: true,
      navbarLinks: {
        knowledge: {
          type: 'routerLink',
          visible: true,
        },
        home: {
          type: 'anchor',
          visible: true,
        },
        account: {
          visible: false,
        },
      },
    },
    accountDashboard: {
      view: 'standard',
      holdingsImagePath: '@/assets/illustrations/my-funds.svg',
      pendingPaymentsInfoboxEnabled: false,
    },
    accountBalance: {
      customImage: false,
      customWrapperClass: 'test',
      animationsEnabled: true,
    },
    accountInvestments: {
      transactionAmountWrapperClassName: 'test',
      transactionAmountLastColumnClassName: 'test',
      boughtSharesExcludeLoan: false,
      checkForPremium: true,
    },
    accountSingleFund: {
      availableProjectsTotal: 'available',
      textCheckFundType: false,
      displayInterest: true,
      overlayClassEnabled: false,
      firstWithDownloadVersion: 'assets',
      totalsSectionEnabled: true,
      totalsSectionTwoEnabled: false,
      totalsSource: 'investment',
      dividendsSource: 'dividends',
      netValueCheckIfNoInvestments: false,
      includeIcon: false,
      completedBoxTextClassEnabled: true,
      premiumClassEnabled: true,
      projectHistoryEnabled: false,
      totalEarningsEnabled: false,
      totalCostsEnabled: false,
      projectHistoryTabs: [],
    },
    property: {
      secondaryDocUnitedStates: false,
      secondaryDocThreshold: false,
      enableVentureDetails: false,
      riskProperty: false,
      availableNumbersEnabled: true,
      showInterestEnabled: true,
      showContactAddress: false,
      transformedDateTimeEnabled: false,
    },
    propertiesGridFund: {
      disableDocsIfNotInvested: false,
      contentsCardEnabled: true,
      subtitle: 'test',
      premiumFundDividendClassName: 'properties__premium-fund',
    },
    properties: {
      toolbarSearchEnabled: true,
      dateSortingProperty: 'createdDateTime',
      titleEnabled: false,
    },
    identificationRequest: {
      dateOfBirthFormat: 'DD/MM/YYYY',
      uploadPassport: true,
      businessRiskClassProp: false,
      businessExperienceProp: false,
      worldRiskClassProp: false,
      worldExperienceProp: false,
      businessInputs: {
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        initials: {
          inputName: 'initials',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.initials',
          inputType: 'formInput',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        middleName: {
          inputName: 'middleName',
          rules: 'required|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.middleName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: 'tel',
          label: 'common.telephone',
          inputType: 'formInput',
        },
        dateOfBirth: {
          inputName: 'dateOfBirth',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.dateOfBirth',
        },
        bankAccount: {
          inputName: 'bankAccount',
          rules: 'required|alpha_num|max:40|min:18',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.iban',
          inputType: 'formInput',
        },
        bankAccountOwner: {
          inputName: 'bankAccountOwner',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.bankAccountOwner',
          inputType: 'formInput',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.address',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.houseNumber',
          inputType: 'formInput',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
        companyName: {
          inputName: 'companyName',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.companyName',
          inputType: 'formInput',
        },
        kvkNumber: {
          inputName: 'kvkNumber',
          rules: 'required|integer',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.business.kvkNumber',
          inputType: 'formInput',
        },
        kvkImage: {
          inputName: 'kvkImage',
          rules: '',
          class: 'col--12 upload__passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.business.uploadYourKvKImage',
        },
        passport: {
          inputName: 'passport',
          rules: '',
          class: 'col--12 upload__passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.world.uploadYourPassport',
        },
        passportExpirationDate: {
          inputName: 'passportExpirationDate',
          rules: 'required',
          class: 'col--12',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.passportExpirationDate',
        },
        receivePhysicalMailings: {
          inputName: 'receivePhysicalMailings',
          rules: 'required',
          class: 'checkbox',
          autocomplete: false,
          type: '',
          inputType: 'checkbox',
          label: 'common.receivePhysicalMailings',
        },
      },
      worldInputs: {
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        initials: {
          inputName: 'initials',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.initials',
          inputType: 'formInput',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        middleName: {
          inputName: 'middleName',
          rules: 'required|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.middleName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: 'tel',
          label: 'common.telephone',
          inputType: 'formInput',
        },
        dateOfBirth: {
          inputName: 'dateOfBirth',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.dateOfBirth',
        },
        bankAccount: {
          inputName: 'bankAccount',
          rules: 'required|alpha_num|max:40|min:18',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.iban',
          inputType: 'formInput',
        },
        bankAccountOwner: {
          inputName: 'bankAccountOwner',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.bankAccountOwner',
          inputType: 'formInput',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.address',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.houseNumber',
          inputType: 'formInput',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
        passport: {
          inputName: 'passport',
          rules: '',
          class: 'col--12 upload__passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.world.uploadYourPassport',
        },
        passportExpirationDate: {
          inputName: 'passportExpirationDate',
          rules: 'required',
          class: 'col--12',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.passportExpirationDate',
        },
        receivePhysicalMailings: {
          inputName: 'receivePhysicalMailings',
          rules: 'required',
          class: 'checkbox',
          autocomplete: false,
          type: '',
          inputType: 'checkbox',
          label: 'common.receivePhysicalMailings',
        },
      },
    },
    identification: {
      questionnaireEnabled: true,
    },
    identificationForm: {
      redirectToAccount: false,
      buttonText: 'dashboard.funds.myPortfolio',
    },
    settings: {
      itemPaddingAdded: false,
      details: 'standard',
      detailsChange: false,
      identification: true,
      settingsAutoReditect: 'identification',
    },
    register: {
      newsletter: false,
      privacyStatement: false,
    },
    authReset: {
      includePleaseCheckInboxInToast: false,
    },
    dropdownMenu: {
      dropdownMenuType: 'standard',
      settingsNavigation: 'settings',
      dropdownMenuItems: {
        portfolio: {
            visible: true,
        },
        settings: {
            visible: true,
        },
      },
    },
    investor: {
      passportPropertyName: 'passport',
    },
  },
  emails: {
    stopIncorrectTemplateIds: true,
    from: 'test',
    sharesBought: {
      format: 'full',
      templateId: 'test',
      enabled: false,
      signatureChoiceOnline: '',
      fromAdminTemplateId: '',
      sendlegalDocs: false,
    },
    sharesBoughtAdminNotification: {
      to: 'vince@bloqhouse.com',
      templateId: '',
      enabled: false,
      signatureChoiceOnline: '',
    },
    verifyEmail: {
      templateId: 'test',
      format: 'test',
    },
    passwordReset: {
      templateId: 'test',
      format: 'test',
    },
    identificationRequest: {
      approved: {
        templateId: 'test',
      },
      rejected: {
        templateId: 'test',
      },
      format: '',
    },
    createIdentificationRequest: {
      fromClientName: false,
      toClientName: false,
      toClientEmail: [],
    },
    translations: {
      sharesBoughtInstrument: {
        subject: 'test',
        content: 'test',
      },
    },
    contactSupportByEmail: {
      from: '',
      to: '',
      cc: false,
    },
  },
};
